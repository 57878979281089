import { render, staticRenderFns } from "./EditEventDialog.vue?vue&type=template&id=2edc7f80"
import script from "./EditEventDialog.vue?vue&type=script&lang=js"
export * from "./EditEventDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports